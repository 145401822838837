export default [
  {
    path: '/company/students',
    name: 'company.students',
    component: () => import('@/views/CompanyAdmin/Student.vue'),
    meta: {
      pageTitle: 'students',
      breadcrumb: [
        {
          text: 'Students',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'students',
    },
  },
]
