export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'home',
      action: 'manage',
      resource: 'tutors',
    },
  },
  {
    path: '/positions',
    name: 'positions',
    component: () => import('@/views/Admin/Position.vue'),
    meta: {
      pageTitle: 'positions',
      action: 'manage',
      resource: 'positions',
    },
  },
  {
    path: '/levels',
    name: 'levels',
    component: () => import('@/views/Admin/Level.vue'),
    meta: {
      pageTitle: 'levels',
      action: 'manage',
      resource: 'levels',
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/Admin/Company.vue'),
    meta: {
      pageTitle: 'companies',
      action: 'manage',
      resource: 'companies',
    },
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/views/Admin/Job.vue'),
    meta: {
      pageTitle: 'jobs',
      action: 'manage',
      resource: 'jobs',
    },
  },
  {
    path: '/talk-centers',
    name: 'talk-centers',
    component: () => import('@/views/Admin/TalkCenter.vue'),
    meta: {
      pageTitle: 'talk_centers',
      action: 'manage',
      resource: 'talkCenters',
    },
  },
  {
    path: '/talk-operation-times',
    name: 'talk-operation-times',
    component: () => import('@/views/Admin/TalkOperationTime.vue'),
    meta: {
      pageTitle: 'talk_operation_times',
      action: 'manage',
      resource: 'talkOperationTimes',
    },
  },
  {
    path: '/user-management/admin',
    name: 'user-management.admin',
    component: () => import('@/views/Admin/UserManagement/Admin.vue'),
    meta: {
      pageTitle: 'admin',
      action: 'manage',
      resource: 'userManagement',
    },
  },
  {
    path: '/user-management/tutor',
    name: 'user-management.tutor',
    component: () => import('@/views/Admin/UserManagement/Tutor.vue'),
    meta: {
      pageTitle: 'tutor',
      action: 'manage',
      resource: 'userManagement',
    },
  },
  {
    path: '/user-management/company-admin',
    name: 'user-management.company-admin',
    component: () => import('@/views/Admin/UserManagement/CompanyAdmin.vue'),
    meta: {
      pageTitle: 'company_admin',
      action: 'manage',
      resource: 'userManagement',
    },
  },
  {
    path: '/series',
    name: 'series',
    component: () => import('@/views/Admin/Series/Series.vue'),
    meta: {
      pageTitle: 'series',
      action: 'manage',
      resource: 'series',
    },
  },
  {
    path: '/rotations',
    name: 'rotations',
    component: () => import('@/views/Admin/Rotation.vue'),
    meta: {
      pageTitle: 'rotations',
      action: 'manage',
      resource: 'rotations',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Admin/Product.vue'),
    meta: {
      pageTitle: 'products',
      action: 'manage',
      resource: 'products',
    },
  },
  {
    path: '/students',
    name: 'students',
    component: () => import('@/views/Admin/Student/Index.vue'),
    meta: {
      action: 'manage',
      resource: 'userManagement',
    },
  },
  {
    path: '/students/:id',
    name: 'students.info',
    component: () => import('@/views/Admin/Student/StudentDetails.vue'),
    meta: {
      action: 'manage',
      resource: 'userManagement',
    },
  },
  {
    path: '/talk/today/schedule',
    name: 'talk.today.schedule',
    component: () => import('@/views/Admin/Talk/TodaySchedule.vue'),
    meta: {
      pageTitle: 'today',
      action: 'manage',
      resource: 'userManagement',
    },
  },
  {
    path: '/talk/week/schedule',
    name: 'talk.week.schedule',
    component: () => import('@/views/Admin/Talk/WeekSchedule.vue'),
    meta: {
      pageTitle: 'week',
      action: 'manage',
      resource: 'userManagement',
    },
  },
]
