export default [
  {
    path: '/play1',
    name: 'play1',
    component: () => import('@/views/Playground/Product.vue'),
    meta: {
      pageTitle: 'products',
      action: 'manage',
      resource: 'products',
    },
  },
]
