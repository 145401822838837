export default [
  {
    path: '/my-schedules',
    name: 'my.schedules',
    component: () => import('@/views/Tutor/MySchedule.vue'),
    meta: {
      pageTitle: 'my_schedules',
      action: 'can',
      resource: 'teach',
    },
  },
  {
    path: '/post-lessons/writing',
    name: 'post_lessons.writing',
    component: () => import('@/views/Tutor/PostLessons/Writing.vue'),
    meta: {
      pageTitle: 'writing',
      action: 'can',
      resource: 'teach',
    },
  },
]
